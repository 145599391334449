.fade {
	opacity: 1;
}

.login-image {
	height: 500px;
	position: relative;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../images/1622659552333.png");
}

@media screen and (max-width: 600px) {
	.login-image {
		visibility: hidden;
		clear: both;
		float: left;
		margin: 10px auto 5px 20px;
		width: 28%;
		display: none;
	}
}

.card-1N {
	padding: 0px;
}

@media screen and (min-width: 700px) {
	.card-1N {
		margin-top: 100px;
	}
}

.heading-text {
	color: white;
	font-size: 20px;
}

@media screen and (min-width: 700px) {
	.heading-text {
		font-size: 40px;
	}
}

.fade {
	opacity: 1;
}

.login-image {
	height: 500px;
	position: relative;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../images/1622659552333.png");
}

@media screen and (max-width: 600px) {
	.login-image {
		visibility: hidden;
		clear: both;
		float: left;
		margin: 10px auto 5px 20px;
		width: 28%;
		display: none;
	}
}

.card-1N {
	padding: 0px;
}

@media screen and (min-width: 700px) {
	.card-1N {
		margin-top: 50px;
	}
}

.heading-text {
	color: white;
	font-size: 20px;
}

@media screen and (min-width: 700px) {
	.heading-text {
		font-size: 40px;
	}
}
